import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Accordion, Card } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnglesUp,
  faArrowDown,
  faArrowUp,
  faAsterisk,
  faClock,
  faDollarSign,
  faHorseHead
} from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../../modules/common/components/seo';
import { SectionHeader } from '../../modules/common/components/section-header';

import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './characters.scss';
import './tier-list.scss';
import { NikkeCharacter } from '../../modules/nikke/common/components/nikke-character';
import { NIKKECharacterRatings } from '../../modules/nikke/data/character-ratings';

const NikkeTierListPage: React.FC = () => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    NIKKECharacterRatings
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'R', value: 'R' },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_electric.png"
                width={20}
                alt="Electric"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_fire.png"
                width={20}
                alt="Fire"
              />
            )
          },
          {
            value: 'Iron',
            tooltip: 'Iron',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_iron.png"
                width={20}
                alt="Iron"
              />
            )
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_water.png"
                width={20}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_wind.png"
                width={20}
                alt="Wind"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'AR',
            tooltip: 'Assault Rifle',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_ar.png"
                width={40}
                alt="Assault"
              />
            )
          },
          {
            value: 'MG',
            tooltip: 'Minigun',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_minigun.png"
                width={40}
                alt="Minigun"
              />
            )
          },
          {
            value: 'RL',
            tooltip: 'Rocket Launcher',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_rocket.png"
                width={40}
                alt="Rocket Launcher"
              />
            )
          },
          {
            value: 'SG',
            tooltip: 'Shotgun',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_shotgun.png"
                width={40}
                alt="Shotgun"
              />
            )
          },
          {
            value: 'SMG',
            tooltip: 'SMG',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_smg.png"
                width={40}
                alt="SMG"
              />
            )
          },
          {
            value: 'SR',
            tooltip: 'Sniper Rifle',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_sniper.png"
                width={40}
                alt="Sniper Rifle"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Attacker',
            tooltip: 'Attacker',
            image: (
              <StaticImage
                src="../../images/nikke/icons/class_attacker.png"
                width={20}
                alt="Attacker"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../images/nikke/icons/class_defender.png"
                width={20}
                alt="Defender"
              />
            )
          },
          {
            value: 'Supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../images/nikke/icons/class_support.png"
                width={20}
                alt="Supporter"
              />
            )
          }
        ]
      },
      {
        key: 'burst',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'I',
            tooltip: 'Burst I',
            image: (
              <StaticImage
                src="../../images/nikke/icons/type_1.png"
                width={20}
                alt="Type I"
              />
            )
          },
          {
            value: 'II',
            tooltip: 'Burst II',
            image: (
              <StaticImage
                src="../../images/nikke/icons/type_2.png"
                width={20}
                alt="Type II"
              />
            )
          },
          {
            value: 'III',
            tooltip: 'Burst III',
            image: (
              <StaticImage
                src="../../images/nikke/icons/type_3.png"
                width={20}
                alt="Type III"
              />
            )
          }
        ]
      },
      {
        key: 'manufacturer',
        type: 'dropdown',
        values: [
          { label: 'Any Manufacturer', value: 'all' },
          {
            label: 'Abnormal',
            value: 'abnormal'
          },
          {
            label: 'Elysion',
            value: 'elysion'
          },
          {
            label: 'Missilis',
            value: 'missilis'
          },
          {
            label: 'Pilgrim',
            value: 'pilgrim'
          },
          {
            label: 'Tetra',
            value: 'tetra'
          }
        ]
      },
      // { key: 'speciality', type: 'dropdown', values: NikkeSpecialitiesArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = NIKKECharacterRatings;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.Name.toLowerCase().indexOf(
              activeFilters.searchText.toLowerCase()
            ) > -1
        );
        filtered = lodash.uniqBy(filtered, 'ID');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.Rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.Element.indexOf(activeFilters.element) > -1
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.Weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.Class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.manufacturer && activeFilters.manufacturer !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.Manufacturer.toLowerCase() ===
            activeFilters.manufacturer.toLowerCase()
        );
      }
      if (activeFilters.burst && activeFilters.burst !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.Burst_Type.toLowerCase() === activeFilters.burst.toLowerCase()
        );
      }
      // if (activeFilters.speciality && activeFilters.speciality !== 'all') {
      //   filtered = filtered.filter(
      //     (emp) =>
      //       emp.specialities &&
      //       emp.specialities.length > 0 &&
      //       emp.specialities.indexOf(activeFilters.speciality) > -1
      //   );
      // }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, visibleCharacters, sortOption]);

  const sortingOptions = [
    { value: 'Story_LD', label: 'Story (early)' },
    { value: 'Story_HD', label: 'Story (late)' },
    { value: 'Boss', label: 'Bossing' },
    { value: 'PVP', label: 'PVP' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke nikke-tier'}
      game="nikke"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/nikke/categories/category_tierlist.png"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>NIKKE Tier list</h1>
          <h2>
            Tier list for Goddess of the Victory: NIKKE that rates all
            characters.
          </h2>
          <p>
            Last updated: <strong>30/03/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-page nikke ">
        <SectionHeader title="Tier List" />
        <Accordion className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the tier list</Accordion.Header>
            <Accordion.Body>
              <p>
                Previously, we tried to emphasize the main strengths of each
                Nikke and assess them accordingly. This allows certain Nikkes to
                rank very high but only for a specific purpose, causing a lot of
                misunderstanding. Therefore, moving forward update, we have
                decided to also consider the impact that each Nikke gives and
                analyze their overall usage in various game modes, including the
                recurrent Union Raid and Solo Raid.
              </p>
              <p>
                Remember that, with each new unit released, the tier list might
                shift to adapt to the new meta. In the tier lists below, each
                unit is evaluated independently based on their Burst sequence.
                For instance, avoid comparing Burst II units and Burst III units
                of the same rank. Instead, view each Burst list separately from
                the rest.
              </p>
              <p>
                <strong>Early vs High Deficit story</strong>
              </p>
              <p>
                <strong>Story (Low-deficit)</strong> is aimed toward beginners
                who are just starting out the game, thus not owning most of the
                meta characters yet and are forced to rely on alternatives. It
                is also less harsh when it comes to stat penalty from CP
                deficit, and the tier list should still be applicable for
                late-game clearing without high CP deficits. Note that this tier
                list assumes that OL is unavailable or has inefficient rolls,
                but expects proper teambuilding in cases like A2, where a healer
                is needed alongside her. This tier list is also applicable for
                Simulation Room: OverClock mode, since it has no Stat penalty at
                all.
              </p>
              <p>
                <strong>Story (High Deficit)</strong> refers to any late-game
                story-clearing content where people attempt to clear stages,
                whether in Campaign or Tower, with over &gt;25% CP deficit.
                Several features of this tier list include:
              </p>
              <ul>
                <li>
                  Some stages in later chapters have special mechanics, such as
                  hitcount, which can be handled easily by MG but not other
                  weapon types.
                </li>
                <li>
                  All units are assumed to have been adequately provided OL gear
                  and well-invested skill wise.
                </li>
                <li>
                  Extremely high CP deficit leads to a massive damage reduction,
                  which makes ATK buff more valuable.
                </li>
                <li>
                  This tier list assumes that people have access to a more
                  complete roster, thus team building becomes the primary
                  concern instead of individual performance.
                </li>
                <li>
                  This tier list appears to have more emphasis on faster
                  rotations and higher burst gen, as they allow you to kill
                  enemies before they can even kill you.
                </li>
                <li>
                  This tier list is a matter of who kills whom first. It's not
                  damage. As long as the enemy dies before they shoot, you gain
                  the upper hand. In fact, high CP deficits make enemy hit
                  harder, so tanking isn't really the ideal way to survive.
                  Offense is a good defense.
                </li>
              </ul>
              <p>
                <strong>Special markings:</strong>
              </p>
              <div className="special-markings">
                <ul>
                  <li>
                    <FontAwesomeIcon
                      icon={faHorseHead}
                      width="18"
                      className="treasure"
                    />{' '}
                    - has a Treasure available that amplifies their kit,
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faClock}
                      width="18"
                      className="limited"
                    />{' '}
                    - is a limited character that isn't available in the general
                    pool,
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      width="18"
                      className="cost"
                    />{' '}
                    - heavy investment is required to play the character at
                    their full potential.
                  </li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Meta Lines</Accordion.Header>
            <Accordion.Body>
              <p>
                The concept of usability in each game-mode can be different,
                even if a unit is good. Tier Lists tend to only show the
                strengths of certain characters but can fail to show how usable
                they might be in situations. An SS tier character in Campaign
                Tier List, although strong, needs to compete for a spot in 1
                team for campaign which only has 5 units. At the same time an SS
                tier character in PvP or Boss (Solo), who are again very strong
                units, compete for a spot in 3 to 5 teams! (15-25 spots). Hence,
                an SS tier Campaign unit will struggle to find a spot for
                Campaign, while an SS tier Boss(Solo)/PvP unit will see plenty
                of use in Raids / Special Arena! This is what we call "slot
                pressure".
              </p>
              <p>
                With the addition of Meta Lines, we intend to showcase the
                usability of a Nikke, and whether or not they really are worthy
                of investments or not depending on the user's play-style using:
              </p>
              <ul>
                <li>
                  <strong className="purple">Meta Line</strong>
                </li>
                <ul>
                  <li>
                    <strong>Units above the purple line</strong>: Meta, Highly
                    Usable Unit. Will not have a problem to fit into teams. The
                    absolute best units for this game mode. These units make the
                    game easy.
                  </li>
                </ul>
                <li>
                  <strong className="green">
                    Best replacement / Situational
                  </strong>
                </li>
                <ul>
                  <li>
                    <strong>Units above green line</strong>: Either they are the
                    best units to use if you don't have units above purple, or
                    their use is situational (i.e. depends on element or design
                    of stage/boss, requirement of heals/taunts, etc.). An
                    example is: Maid Privaty would be used over the likes of 2B
                    against a boss like Crystal Chamber in Solo Raids, while the
                    opposite would happen if the Boss was fire weak and far
                    away, like Stormbringer Solo Raid.
                  </li>
                  <li>
                    <strong>Units below green line</strong>: These units are
                    often used when players do not have units above green line.
                    If you have all units, these units will struggle to find a
                    spot in meta teams, and are often used for their niche.
                    (such as Ade's buff dispel, Nihilister being used for fire
                    elemental QTE barriers, Admi being used for her reload
                    speed, etc.)
                  </li>
                </ul>
              </ul>
              <p>
                <strong>Note</strong>: Meta Lines are more applicable for
                commanders who have most units already available. If you are a
                New Commander, using tiers as a literal guide will be more
                beneficial overall.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Ratings explained</Accordion.Header>
            <Accordion.Body>
              <h6>Story (LD and HD)</h6>
              <ul>
                <li>
                  <strong>SSS</strong> - Powerful, versatile units that can be
                  used to brute force almost ALL campaign stages. Either they
                  have godly damage output, or they have unrivaled supporting
                  capabilities. They have a comprehensive kit that barely has
                  any weaknesses.
                </li>
                <li>
                  <strong>SS</strong> - Strong units featuring remarkable DPS
                  and supportive capability. Best choices to replace units from
                  SSS tiers. In campaign meta, they are slightly less usable
                  compared to units in SSS, and are used situationally depending
                  on stage design or element. However, they are deployed from
                  time to time even outside their best form because they are
                  quite versatile.
                </li>
                <li>
                  <strong>S</strong> - These units are much more situational
                  compared to their SS counterparts but can be used with success
                  if you know what you are doing (or have no access to better
                  alternatives). They function more as replacements, but can be
                  the main DPS when it's their own element or a situation
                  heavily favoring them. Otherwise, their performance is weaker,
                  and pushing with them will not feel as rewarding/comfortable.
                </li>
                <li>
                  <strong>A</strong> - These units are rarely effective, and are
                  technically out of the actual meta for campaign. They cannot
                  compete with the top units, but occasionally might be able to
                  help progress in a stage or two. Tribe tower or early game
                  meta units mostly exist here.
                </li>
                <li>
                  <strong>B</strong> - These units are very hard to use. They
                  can even be considered replacements for the tribe tower units.
                  In campaign however, there is at best one or two situations in
                  30+ chapters where they may be able to fit in.
                </li>
                <li>
                  <strong>C</strong> - Highly niche. It's improbable that these
                  units are ever used in high deficit, but they are at the very
                  least better than D tier units. Maybe in tribe tower, these
                  units can rarely find a use.
                </li>
                <li>
                  <strong>D</strong> - These units are barely usable. They
                  provide little to nothing for their teams, have harsh
                  requirements for their skills, and have little effect even if
                  their skills are activated properly.
                </li>
                <li>
                  <strong>E</strong> - Units with bad kits that should be
                  avoided if possible. A lot of waifus tho.
                </li>
                <li>
                  <strong>F</strong> - R unit hangout stage
                </li>
              </ul>
              <h6>Bossing</h6>
              <ul>
                <li>
                  <strong>SSS</strong> - Powerful, versatile units that have a
                  godly damage output or have unrivaled supporting capabilities.
                  They have a comprehensive kit that works against almost every
                  boss, and they are often the primary choice if there is no
                  better substitute.
                </li>
                <li>
                  <strong>SS</strong> - Strong units that are often the second
                  choice. Impactful DPS and supportive skills. They are very
                  likely deployed in every season of Solo Raid. Some of them
                  also function as enablers and must be present for their most
                  optimal team to flourish. Without them, the overall usability
                  of the team would plummet.
                </li>
                <li>
                  <strong>S</strong> - Units that have decent DPS and acceptable
                  supportive capability but are secondary to their big sisters
                  in the SS rank. They either complement each other, require
                  them to excel, or help push them to their true limits. They
                  are unlikely to be deployed alone, and some of them may be
                  situational.
                </li>
                <li>
                  <strong>A</strong> - Units that are waiting for that one boss.
                  Highly specific units that are used when the right time and
                  situation comes. However, in other scenarios, these units will
                  falter.
                </li>
                <li>
                  <strong>B</strong> - Casual friendly usable units that can
                  either help with survivability, deal okayish damage, or can at
                  the very least buff their team to an extent. These units are
                  not meta but if the player has incomplete rosters, they can
                  definitely make use of them. In extremely harsh situations,
                  such as need fkr cleanse, these units may shine bright.
                </li>
                <li>
                  <strong>C</strong> - Extremely mid tier units. They are useful
                  can be for players with incomplete rosters who come up against
                  Elemental Shields and don't have enough units of the right
                  element.. However, their performance is never on par with the
                  top units. These units have glaring problems but are at the
                  very least usable in their own extreme niche.
                </li>
                <li>
                  <strong>D</strong> - These units are barely usable. They
                  provide little to nothing for their teams, have harsh
                  requirements just to activate their skills, and have little
                  effect even if their skills are activated properly.
                </li>
                <li>
                  <strong>E</strong> - R unit hangout room.
                </li>
              </ul>
              <h6>PVP</h6>
              <ul>
                <li>
                  <strong>SSS</strong> - These units have high versatility and
                  strength. They are the staple of all PvP rosters. You can say
                  that you are bricked if you don't have one of them. Queens of
                  PvP.
                </li>
                <li>
                  <strong>SS</strong> - These units have strong overall
                  performance, and some of them occupy a certain niche where no
                  other Nikkes can replace them. They are legendary in these
                  regards.
                </li>
                <li>
                  <strong>S</strong> - Units from this tier and below are
                  starting to show weakness when used on PvP Defense. Either
                  they require a specific setup, demand high investments, or are
                  easily counterable. They are often used as filler units but
                  are decent alternatives (and sometimes superior). Some are
                  extremely potent on Attack.
                </li>
                <li>
                  <strong>A</strong> - These units have above average
                  performance but are nowhere near impactful as the top ones.
                  However, they are still commonly seen on Attack, but may need
                  high investments to be used in DEF teams.
                </li>
                <li>
                  <strong>B</strong> - Similar to A tier, but their impact
                  extends to a lesser degree. They have niche uses and can be
                  strong with proper investments, but they're not core to any
                  teams. They are situationally used on Attack, rarely on
                  Defense.
                </li>
                <li>
                  <strong>C</strong> - These units may not be part of the meta,
                  but are considered as the ultimate baseline of what can be
                  considered usable in PvP in any sense. Be it a niche, average
                  burst generation, or decent usability for incomplete rosters,
                  these Nikkes can possibly be used to good effect.
                </li>
                <li>
                  <strong>D</strong> & <strong>E</strong> - Units from these
                  tiers are barely usable and have weak impact. They are just
                  replacements for players with incomplete rosters.
                </li>
                <li>
                  <strong>F</strong> - R unit hangout zone.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Changelog <span className="tag updated">Updated!</span>
            </Accordion.Header>
            <Accordion.Body>
              <h6>30.03.2025</h6>
              <p>
                Added <strong>Bready</strong> to the tier list.
              </p>
              <h6>27.03.2025</h6>
              <p>
                <strong>General idea for PvE tier list changes:</strong>
              </p>
              <p>
                Shift Up has recently released too many meta breaking units,
                which has led to the tier list being very top heavy. Our aim
                with these changes is to make the SS and S tiers very very
                relevant, and to also fit better than before, much more usable
                units into the A tier for all tier lists. There may be a decent
                gap of performance between SS tier and below.
              </p>
              <p>
                <strong>Bossing tier list changes:</strong>
              </p>
              <ul>
                <li>
                  <strong>Red Hood B3</strong> SSS &gt; SS - With the rise of
                  elemental meta, and with Shift Up recently making much less
                  bosses that favour pierce units, Red Hood is seen less and
                  less as a B3 for Neutral Elements. Units like Cinderella,
                  Scarlet Black Shadow, Asuka: Wille simply deal much more
                  damage when there is no pierce available, and units like Alice
                  are much more synergistic to other hyper meta units. She is
                  still strong and situationally used as B3, but is not at the
                  level of the other B3 units unless there is pierceable
                  parts/core on the boss.
                </li>
                <li>
                  <strong>Phantom</strong> B &gt; A - Due to the recent release
                  of the Maid Duo, Phantom has been a decent boss damage dealer
                  when the boss spawns mobs, as shown during MaterialH's Solo
                  Raid. Even when the boss doesn't spawn mobs, she can still
                  shine against Water-weak bosses.
                </li>
              </ul>
              <p>
                <strong>Campaign (High Deficit) tier list changes:</strong>
              </p>
              <ul>
                <li>
                  <strong>Asuka & Naga</strong> SSS &gt; SS - Literally nothing
                  has changed. We have just made the SS tier a lot more relevant
                  for future releases and a lot more strict. A distinct
                  comparison will now be seen between SSS and SS tier units, and
                  a decent gap in performance can be felt between SS and S tier
                  units.
                </li>
                <li>
                  <strong>Liter & DWife</strong> SSS &gt; SS - With the
                  introduction of Rapi Red Hood, Liter is now no longer the
                  strongest Burst 1 unit. Only when Rapi wants to be used as a
                  B3 on a specific stage do Liter, DWife and Rouge become
                  relevant.
                </li>
                <li>
                  <strong>Rapunzel & Tia</strong> SS &gt; S
                </li>
                <li>
                  <strong>Blanc, Mari, Maid Anchor, Maid Mast & Grave</strong>{' '}
                  SS &gt; S - These changes have been made due to the barrier of
                  entry to the higher tiers is now extremely restrictive. At
                  higher deficits, Crown is king, and only the units who work
                  with her can be considered the ultimate meta units. These B2
                  supports are considered as the best replacements for Crown and
                  her allies.
                </li>
                <li>
                  <strong>
                    Asuka: Wille, Modernia, Ein, Scarlet, Rei Ayanami, Anis
                    Sparkling Summer, Maxwell & Rei Ayanami (Tentative Name)
                  </strong>{' '}
                  SS &gt; S - These changes have been made to reflect the
                  current campaign meta for B3 units. B3 units are used in
                  accordance to the element of the stage. Only units who perform
                  at a superb level on neutral elements are ranked in higher
                  tiers now for this tier list.
                </li>
                <li>
                  <strong>B.Alice & Dorothy</strong> S &gt; A
                </li>
                <li>
                  <strong>Laplace (Treasure), Snow White, Noir ^ Emilia</strong>{' '}
                  S &gt; A
                </li>
                <li>
                  <strong>S.Mary & xMica</strong> A &gt; B
                </li>
                <li>
                  <strong>Centi, Dolla, S.Helm, Marciana & Rem</strong> A &gt; B
                </li>
                <li>
                  <strong>xMaiden</strong> A &gt; B
                </li>
                <li>
                  <strong>Anis, Admi, Poli, Elegg, Quency & Rupee</strong> B
                  &gt; C
                </li>
              </ul>
              <p>
                <strong>Campaign (Low Deficit) tier list changes:</strong>
              </p>
              <ul>
                <li>
                  <strong>Helm (Treasure)</strong> SS &gt; SSS - Pure Greatness.
                  Burst Gen, Buffs, and synergy with crown. Everything a casual
                  player needs. For sure one of the best units to use in almost
                  any team for low deficit gameplay, a great sub for Naga, and
                  can be used as off burst unit even in crown less teams, such
                  as with Grave, Rouge-Blanc, Mari, S.Rosanna, etc. Helm is an
                  SSS tier unit for low deficit campaign.
                </li>
                <li>
                  <strong>Dorothy & Rapunzel</strong> SS &gt; S - Dorothy can be
                  used in low deficit gameplay to high effect, but the issue is
                  her CDR, which is a problem units like Rouge, Liter etc do not
                  have. As for Rapunzel, the introduction of units like
                  HelmTreasure, Anchor, etc., she is just not at the same level,
                  and drops severely in comparison as a usable unit. She has
                  some specific use cases where she will excel in, but otherwise
                  it is tough to fight for the off burst slot.
                </li>
                <li>
                  <strong>Mari & SRosanna</strong> SS &gt; S - Burst 2 units who
                  are just simple buffers have been demoted to form a new
                  definition of S tier for low deficit. Units like Grave, Blanc,
                  Maids have an extra bonus such as healing, burst gen, or
                  personal damage dealt bonus to them which makes them a tier
                  apart.
                </li>
                <li>
                  <strong>
                    Maxwell, S.Anis, Emilia, LaplaceTreasure, Rei Ayanami & Rei
                    Ayanami Tentative Name:
                  </strong>{' '}
                  SS &gt; S - B3 is a very tough competitive spot to fill in
                  campaign. In low deficit campaign, the distinction between
                  good and bad units is shown by which units are able to help
                  you clear campaign wall stages. These units, most of the time,
                  just lack the abilities required to clear wall stages. These
                  units are still very good to use, but units in SS and SSS
                  tiers can perform just that extra mile better. On their own
                  element, they shine the brightest and can be subbed in for
                  some elemental damage benefits.
                </li>
                <li>
                  <strong>S.Mary &B.Alice</strong> S &gt; A
                </li>
                <li>
                  <strong>Snow White & A2</strong> S &gt; A
                </li>
                <li>
                  <strong>xMica, N102, Rumani & Rapunzel Pure Grace</strong> A
                  &gt; B
                </li>
                <li>
                  <strong>S.Sakura, xGuillo & 2B</strong> B &gt; C
                </li>
              </ul>
              <p>
                <strong>PVP:</strong>
              </p>
              <p>
                Our PvP Tier List has been severely outdated because we wanted
                to hold off our changes till the release of Champions Arena, but
                since Shift Up keeps delaying everything, here we are. We have
                made some exciting changes to our tiering system, prioritizing
                defense over offense, but not too much. As you all probably
                know, "Attack wins you games, Defense wins you championships." ~
                Sir Alex Ferguson.
              </p>
              <ul>
                <li>
                  This was full revamp - basically the whole tier list is brand
                  new, so we won't be listing all changes.
                </li>
              </ul>
              <p>
                <strong>Others:</strong>
              </p>
              <ul>
                <li>
                  <strong>Milk (Treasure)</strong> &{' '}
                  <strong>Miranda (Treasure)</strong> have been added to the
                  tier list.
                </li>
              </ul>
              <h6>17.03.2025</h6>
              <ul>
                <li>
                  <strong>Trina</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>07.03.2025</h6>
              <ul>
                <li>
                  <strong>Sakura Suzuhara</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>03.03.2025</h6>
              <ul>
                <li>
                  <strong>Rei Ayanami (Tentative Name)</strong> has been added
                  to the tier list.
                </li>
              </ul>
              <h6>24.02.2025</h6>
              <ul>
                <li>
                  <strong>Asuka Shikinami Langley: Wille</strong> has been added
                  to the tier list.
                </li>
                <li>Updated Modernia Campaign LD/HD Ratings - SSS --&gt; SS</li>
                <ul>
                  <li>
                    Simple reason. Powercrept in terms of utility and damage.
                    Modernia is no longer the best off-burst unit to use in
                    Campaign in the flex spot. Her main utility was that she was
                    able to deal high amounts of damage by providing cover fire
                    and clearing critters between bouts of burst attacks. She
                    also provides nice burst generation due to her double hits
                    and, being MG, assists in destroying hit-count Raptures and
                    projectiles. However, now, we have Asuka: WILLE and
                    Crown—both MG users who are more usable than Modernia.
                    Modernia will still remain a situational unit crucial for a
                    handful of stages. Despite her unmatched comfort, our old
                    Queen who has ruled over the Campaign leaderboards for 2
                    years has now finally be put to rest by the new kids in
                    town.
                  </li>
                </ul>
              </ul>
              <h6>20.02.2025</h6>
              <ul>
                <li>
                  <strong>Helm (Treasure)</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>10.02.2025</h6>
              <ul>
                <li>
                  <strong>Anchor: Innocent Maid</strong> has been added to the
                  tier list.
                </li>
              </ul>
              <h6>03.02.2025</h6>
              <ul>
                <li>
                  <strong>Mast: Romantic Maid</strong> has been added to the
                  tier list.
                </li>
                <li>Updated Boss tier list (Burst 1):</li>
                <ul>
                  <li>
                    <strong>Rouge</strong> SS &gt; SSS - Really, there's one
                    word needed. Cinderella.
                  </li>
                  <li>
                    <strong>Tia</strong> SSS &gt; SS - We have explained this
                    drop in more detail in Naga's explanation. The whole idea
                    behind the drop is that core is much less relevant now in
                    raids, and that Crown is the only true SSS tier support.
                  </li>
                  <li>
                    <strong>Dorothy</strong> SS &gt; S - Our beloved meme, a
                    victim of powercreep. With Rouge, D:KW, Liter simply
                    providing more CDR, as well as more general buffs for their
                    teams, it is tough for Dorothy to keep up. Her parts damage
                    buff is a lot less relevant most of the time, and her DPS
                    isn't high enough for her to stay at the top level. Her use
                    is pushed further down in the presence of Tove, who has
                    allowed SGs to be a force of nature. It is tough to see
                    Dorothy being used outside her own element, but when parts
                    damage is relevant enough, like in last Modernia SR, she can
                    be a very decent choice.
                  </li>
                  <li>
                    <strong>Red Hood (B1)</strong> A &gt; S - With the plethora
                    of B3s being released for some elements, using RedHood has
                    been a remarkable experience in the overall meta. In
                    campaign, we already know how strong she is as a B1 unit,
                    but it appears that even in Raids, she's being used as a B1
                    unit! While for most players it's probably better to use her
                    as a B3, the top ranks seem to love her as a B1 unit,
                    provided the main DPS is doing tons of damage. Cinderella
                    and Rapi: Red Hood are the main benefactors of RHB1. They
                    deal so much damage that it's better to use RH to support
                    them!
                  </li>
                  <li>
                    <strong>B.Alice, Rapunzel, Noise</strong> S &gt; A - Healers
                    are much less relevant now in the grand scheme due to how
                    the meta B2s provide a multitude of skills. With the added
                    survivability of dolls, and with some B3s having decoys and
                    self-heal, it's time to drop our ever loved healers. Don't
                    worry though, anytime ShiftUp decides to increase the damage
                    dealt by a boss by like 2 times the normal amount, these
                    healers will be meta again!
                  </li>
                  <li>
                    Other changes have been made, but it's mostly moving whole
                    category down.
                  </li>
                </ul>
                <li>Updated Boss tier list (Burst 2):</li>
                <ul>
                  <li>
                    <strong>Naga</strong> SSS &gt; SS - While this change is
                    going to be controversial, let us explain our reasoning
                    behind this demotion. Naga has been a crucial bossing unit
                    for ages. Her core damage buffs have been impactful, and she
                    has remained a top pick for content with high core uptime.
                    However, with the recent trend of reducing core uptime in
                    Solo Raid, Naga has become less rewarding to play. Not to
                    mention, we are also recently introduced to HelmTr + Crown
                    pair and Maids, who are technically superior to Naga against
                    coreless enemies. Her ATK buffs remain potent, though, and
                    her synergy with Tia, who provides an ATK DMG buff, helps
                    the duo stay relevant in Solo Raid.
                  </li>
                  <li>
                    <strong>Blanc</strong> SS &gt; S - Blanc's impact on the
                    overall meta has started to weaken, despite her occasional
                    presence. While her Damage Taken buff is magnificent (and
                    Noir's Ammo Refill is powerful for ammo-sensitive units like
                    Rapipi), the need to run Rouge or Noir alongside her has
                    often been limiting. With Mast and Anchor releasing, Blanc's
                    place in the meta is now contested by her rivals Tia + Naga.
                    Therefore, we are lowering her rating by one to make room
                    for future units.
                  </li>
                  <li>
                    <strong>Mari</strong> SS &gt; S - Since EVA collab, Mari has
                    been an overpowering unit. She is the only decent solo B2
                    Electric unit that grants huge teamwide buffs and has screen
                    wipe. Her being a Sniper Rifle user also helps her
                    complement teams that lack battery. However, as a Pierce
                    buffer, her reliance on core existence makes playing her
                    inconvenient. Although bosses who have a permanent core or
                    that periodically respawn their core won’t pose a problem,
                    Mari’s complex mechanics don’t evoke the same powerful
                    feeling as Grave’s streamlined gameplay. While she will
                    remain meta, we have decided to lower her rating by one to
                    make room for future units.
                  </li>
                  <li>
                    <strong>SRosanna</strong> SS &gt; S - Not only are her buffs
                    inferior to the current meta B2 picks, but activating them
                    also requires a very specific condition. Simply put, she has
                    been powercrept. She might return as an elemental filler in
                    Wind-Weak Raids, but not much else.
                  </li>
                  <li>
                    <strong>Biscuit</strong> S &gt; B - Simply has fallen out of
                    fashion, with B2s being much more reliant now, having the
                    ability to heal or just simply are able to provide insanely
                    higher buffs than what Biscuit can give to the team.
                  </li>
                  <li>
                    Other changes have been made, but it's mostly moving whole
                    category down.
                  </li>
                </ul>
                <li>Updated Boss tier list (Burst 3):</li>
                <ul>
                  <li>
                    <strong>Modernia, Noir, Maxwell, Snow White</strong> SS &gt;
                    S - A very simple explanation. With the ever expanding B3
                    roster, the game has more focus on elemental advantage. The
                    S tier has become more focused on Elemental or situational
                    use units, where we feel that these units are better
                    defined. We do not think we will be seeing much of these
                    units off their own element in the future, except on maybe a
                    couple occasions depending on boss parts, and maybe on
                    elements like Water Weak, where there aren't many B3s for
                    their own element to fill out 5 teams. Noir's demotion is
                    tied to Blanc's. By herself, she can still be used
                    effectively to buff ammo as an off burst unit, but an off
                    burst dps or damage buffer may be a better fit most of the
                    time.
                  </li>
                  <li>
                    <strong>Privaty, Helm</strong> S &gt; A - With Dorothy
                    falling off a bit, we feel that Privaty's use also drops
                    down. Her main use from now in our minds is alongside Crown,
                    to allow for infinite ammo comps. As for Helm, healing in
                    general is less of a requirement now since most B2s provide
                    survivability skills for the team. Even some main B3s have
                    self heal or decoys to help the team tank damage.
                  </li>
                  <li>
                    Other changes have been made, but it's mostly moving whole
                    category down.
                  </li>
                </ul>
              </ul>
              <h6>19.01.2025</h6>
              <p>
                <strong>Mana</strong> has been added to the tier list.
              </p>
              <h6>03.01.2025</h6>
              <p>
                <strong>Rapi: Red Hood</strong> has been added to the tier list
                - as both B1 and B3.
              </p>
              <h6>14.12.2024</h6>
              <p>
                <strong>Guillotine: Winter Slayer</strong> has been added to the
                tier list.
              </p>
              <h6>07.12.2024</h6>
              <p>
                <strong>Maiden: Ice Rose</strong> has been added to the tier
                list.
              </p>
              <h6>23.11.2024</h6>
              <p>
                <strong>Flora</strong> has been added to the tier list.
              </p>
              <h6>14.11.2024</h6>
              <p>
                <strong>Rapunzel: Pure Grace</strong> has been added to the tier
                list.
              </p>
              <h6>10.11.2024</h6>
              <p>
                <strong>Grave</strong> has been added to the tier list.
              </p>
              <h6>08.11.2024</h6>
              <p>
                <strong>Cinderella</strong> has been added to the tier list.
              </p>
              <h6>30.10.2024</h6>
              <p>
                We have done massive rework of the LD and HD Story tier list.
                Here are the HD Story changes:
              </p>
              <ul>
                <li>
                  <strong>Noir</strong> (SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  S): Noir has been largely regarded as the less useful nikke of
                  the blanc-noir pair due to the fact that she occupies a slot
                  which is toughly competed. Big names for campaign such as
                  Modernia, Maxwell, using the fire trio (Alice, Asuka, Mod), or
                  using the electric trio (Ein, S.Anis, Scarlet) perform
                  exceptionally better at this position in most situations, and
                  the new Rouge-Blanc pair allows us to successfully run them.
                  The point of this demotion is to show that Rouge-Blanc is a
                  better pairing than Blanc-Noir for campaign.
                </li>
                <li>
                  <strong>Emilia</strong> (SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  S): Emilia has no innate ATK buffs, which is one of the major
                  reasons why she performs at a worse level compared to the
                  other SS and SSS units in High Deficit. While her
                  screenclearing ability is insanely good, her lack of focus
                  damage strength and the lack of ability to buff allies is also
                  a detriment to her, since it makes her a less versatile unit
                  compared to the other units present in SS tier.
                </li>
                <li>
                  <strong>ExiaTreasure</strong> (A{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  S): She has been found to show impressive prowess in Campaign
                  on electric weak stages when fully built and used with the
                  proper teammates, allowing accounts to push at 30%+ deficit on
                  most stages! The new S tier is defined by units that allow for
                  excellent performance shown in their personal elements, and
                  hence Exia has been promoted.
                </li>
                <li>
                  <strong>Rem</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): Rem can realistically only perform on Water Weak stages as
                  a single target DPS unit, like xLudmilla but a weaker version.
                  Her healing is too slow to properly work in High Deficit
                  Campaign stages, and hence she can only rely on her DPS output
                  and ability to buff ATK of RL allies. However, with the lack
                  of proper RL DPS that have a carry potential, Rem is a weaker
                  choice in the overall meta in High Deficit Campaign in the B2
                  categories, since other B2 units can buff more units and can
                  buff them much more effectively.
                </li>
                <li>
                  <strong>Centi</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): It is sad to see our hard working engineer fall out of
                  meta, but it is a reality we have to face. Once at the top of
                  the food chain, Centi falls short of the meta as Shift Up
                  decided to release an insane amount of Burst 2 units that can
                  provide offensive capabilities. Some Burst 2 units such as
                  Naga and Marciana also synergize with Crown and other units to
                  provide a very adequate amount of healing as well as
                  activating buffs. Centi was mainly used for Burst Generation,
                  but units like RedHood, Alice, LaplaceTr, A2 and Emilia are
                  able to take over that role very effectively, leading to
                  Centi's demise in the meta. For the first time since we
                  started our tier lists, Centi falls into A tier.
                </li>
                <li>
                  <strong>Soda: Twinkling Bunny</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): The problem arises in her ability to increase Burst
                  duration. While no other Iron AoE DPS exists, B.Soda ruins the
                  burst rotations which we otherwise would have had due to the
                  presence of a CDR unit to quickly move on to the next burst.
                  Hence, B.Soda has been demoted to A tier. She still can
                  perform to a decent standard against Iron Weak stages which
                  require screen clear if properly built, but will cause
                  problems due to her burst duration increase.
                </li>
                <li>
                  <strong>LaplaceTreasure</strong> (SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  S): In the current meta and huge selection we have for Iron
                  DPS, Laplace's has only been able to shine and compete against
                  others in Iron Weak stages. Her general performance is good
                  but not at the level of other SS tier units, who are able to
                  deal as much damage as Laplace but are also a help to their
                  teammates with buffs, or are at the top of the chain in their
                  abilities, e.g. Scarlet and Rei are the highest AoE Screen
                  clears in the game. The competition with SnowWhite and Maxwell
                  to have a spot alongside Red Hood according to the stage
                  design in Iron Weak stages also gives her a rough time in the
                  overall meta.
                </li>
                <li>
                  <strong>Privaty</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): Privaty is a simple buffer. She cannot perform as a main
                  DPS in most stages. While she does synergize well with Crown
                  and Alice for that infinite ammo, in the overall meta there
                  are better units that may be used, such as Naga, Modernia or a
                  healer. It is very situational that Privaty will be the best
                  off burst unit to use, and even rarer that she will be one of
                  the ideal B3 units to pick.
                </li>
                <li>
                  <strong>Quency: Escape Queen</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): Her performance falters slightly against high-deficit
                  compared to low-deficit content due to her Burst Skill being
                  Distributed Damage, which reduces/removes the possibility of
                  it acting as a screen clear. She is still a useful unit and
                  can help with clearing Water-weak stages as a similar but
                  weaker outlook to XLudmilla. xLudmilla is also better because
                  of the damage taken debuff applies to the rapture, which
                  allows her team to deal more damage.
                </li>
              </ul>
              <p>Here are the LD Story changes:</p>
              <ul>
                <li>
                  <strong>D: Killer Wife</strong> (SSS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  SS): For a while now, D: Killer Wife has been in SSS Low
                  Deficit. She was placed there due to her unique ability:
                  Extremely fast burst rotations, which is more important for
                  High Deficit gameplay since our Nikkes get one shot killed!
                  However, through extensive play testing and comparisons, we
                  believe that Liter and RedHood's B1 form are the absolute best
                  B1s for low deficit gameplay. Both of them have their own
                  weaknesses: That cooldown between the first and second full
                  burst of liter and the pause between the 2nd and 3rd bursts if
                  RedHood. However, the sheer value of buffs they provide is
                  enough to render them a tier above the rest. D:KW provides a
                  relatively niche role in low deficit gameplay, for stages
                  which need faster burst rotations, such as those with the
                  Silence Rapture that delays full bursts.
                </li>
                <li>
                  <strong>Tia, Blanc</strong> (SSS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  SS): Both of them are the victim of the same enemy - Crown.
                  Crown is simply so much better that Tia and Blanc do not have
                  a spot in SSS tier. While they are still excellent choices for
                  Campaign, Crown is just better. Crown buffs better, allows for
                  a 5th slot and a flexible b1cdr slot unlike Blanc. It is just
                  insane how good Crown is as a unit.
                </li>
                <li>
                  <strong>Alice: Wonderland Bunny</strong> (SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  S): Her gig is healing and ammo buffs, both of which are
                  appreciated but not as much as, say, reviving a full unit. In
                  the realm of pure healers, Rapunzel takes her seat at the
                  throne. Regardless, B.Alice is still a strong choice. Another
                  reason for her demotion is that Ammo buffs can be covered
                  through overload lines, or by reload speed from Crown +
                  Resilience cubes as well. Since resilience cube is also an
                  early game cube, B. Alice can sit back in S tier since she is
                  ultimately a more situational requirement in the current
                  campaign meta.
                </li>
                <li>
                  <strong>Centi</strong> (SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  S): The reason for Centi's demotion is similar to the one
                  given in High Deficit. However, players may lack good burst
                  generation during early and midgame, where they may use Centi
                  to fill the slot.
                </li>
                <li>
                  <strong>Noir</strong> (SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  S): The reason for Noir's demotion has already been detailed
                  in High Deficit.
                </li>
                <li>
                  <strong>A2</strong> (SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  S): A2 has been demoted due to her extreme self killing
                  nature, and because of the fact that Emilia is a straight
                  powercreep to her abilities. Emilia can do everything A2 can
                  do but better and without losing HP.
                </li>
                <li>
                  <strong>Phantom</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): Extremely hard and unnatural gameplay. Extremely user
                  unfriendly. Her potential is high but her use is restricted to
                  Elysion Tower only in the overall Meta due to better units
                  existing. In Campaign, it is tough to name a situation where
                  she is the Nikke to use. On Water Weak stages, she might be
                  able to see some use in wave heavy stages, where she is
                  outperformed by Emilia. When focus damage is needed, xLudmilla
                  can outperform her. Phantom has limited use and has hence been
                  demoted to A tier. S tier has also seen demotion of all the
                  older S tier units in the B3 category, so Phantom safely has a
                  spot here.
                </li>
                <li>
                  <strong>Privaty</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): The reason for Privaty's demotion has already been
                  detailed in High Deficit
                </li>
                <li>
                  <strong>Harran, MaidPrivaty</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): Both of them exist as simple AoE screen clears and minimal
                  DPS output otherwise. They are more or less Tribe Tower only
                  Nikkes in the overall Meta, and lack the carry potential that
                  other units in higher tiers may have.
                </li>
                <li>
                  <strong>Snow White: Innocent Days</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): A good kit, but lacks in values. Smolwhite is a victim of
                  the % values present in her kit which make her lack the DPS
                  output needed to perform at a higher level.
                </li>
                <li>
                  <strong>DieselTreasure</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): Powercrept in every sense. All B2 buffers above her have
                  the capability to buff more and without taunting the damage.
                  Taunts can be a detriment to Campaign type stages. She still
                  can provide a remarkable display in the infamous Elysion Tribe
                  Tower, where she has nearly 0 competition apart from Marciana
                  and S.Helm.
                </li>
                <li>
                  <strong>Mica: Snow Buddy</strong> (S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A): No CDR, no heals, short duration ATK buff, and her kit
                  takes too long to fully stack the damage reduction. xMica is a
                  good and usable unit for super early game, but does not find
                  fair use in later stages at all. Her ammo buff is appreciated
                  but the problems with Ammo buffs are the same as the ones
                  listed in B.Alice's tier change note above.
                </li>
              </ul>
              <p>Lower tier changes in both Campaign HD and LD:</p>
              <ul>
                <li>
                  A tier units are very usable units but are usually not good
                  enough to use for players who have complete rosters. All units
                  in and above A tier are able to perform well in Tribe Tower,
                  and can provide results in Campaign as well.
                </li>
                <li>
                  B tier has been set as the baseline for usable units in
                  Campaign. While these units are usable, this does not mean
                  that they should be invested into. They are more or less
                  replacements for when players do not have meta units early on.
                  These units may be able to see use in some stages effectively,
                  but it is highly likely that a meta based account will not
                  have use for them in Campaign type stages. Your Tribe Tower
                  teams may be able to make use of these Nikkes.
                </li>
                <li>
                  C tier has been set up for extremely niche conditions where a
                  unit may shine, such as Noah for tribe tower floor 340's
                  cucumber. They may also see the light in your Tribe Tower
                  teams.
                </li>
                <li>
                  D tier has been setup for units that should be used above E
                  tier units if possible.
                </li>
                <li>
                  E tier units are Nikkes we failed to find proper use for in
                  Campaign and Tower both.
                </li>
                <li>F tier is our new R unit hangout zone.</li>
              </ul>
              <h6>20.10.2024</h6>
              <ul>
                <li>
                  <strong>[Rumani]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>13.10.2024</h6>
              <ul>
                <li>
                  <strong>[Phantom]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>07.10.2024</h6>
              <ul>
                <li>
                  We have removed the <strong>Boss (Adds)</strong> tier list.
                  After receiving a lot of feedback, the split between Boss
                  (Solo) and Boss (Adds) only brought a lot of confusion. So we
                  decided to remove the Adds bossing tier list and just keep a
                  single Bossing tier list moving forward. We are also working
                  on revamping the Story (LD) and PVP tier lists, so expect some
                  big changes to happen there soon!
                </li>
              </ul>
              <h6>06.10.2024</h6>
              <ul>
                <li>
                  <strong>[Quency: Escape Queen]</strong> has been added to the
                  tier list.
                </li>
              </ul>
              <h6>21.09.2024</h6>
              <ul>
                <li>
                  <strong>[Rouge]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>13.09.2024</h6>
              <ul>
                <li>
                  <strong>[Misato Katsuragi]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>06.09.2024</h6>
              <ul>
                <li>
                  <strong>[Mari Makinamii]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>30.08.2024</h6>
              <p>New additions:</p>
              <ul>
                <li>
                  <strong>[Rei Ayanami]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <p>
                We have revamped the Story, Bossing and PVP tier list and here's
                the list of the most important changes. Thank you all for the
                feedback we have received recently!
              </p>
              <ul>
                <li>
                  <strong>Story:</strong>
                </li>
                <ul>
                  <li>
                    <strong>Asuka</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SSS): Core Damage boost proves to be more valuable than
                    expected for Alice, Modernia, and Rei. She also has similar
                    performance to Alice in Campaign, where optimum conditions
                    are unachievable.
                  </li>
                  <li>
                    <strong>B. Soda</strong> (A{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    S): The only Iron wiper for Electric stages. Favorable
                    against stages that spawn a lot of fragile mobs weak to
                    nukes like hers. Full Burst extension can either help
                    solidify or break your team (assuming no CDR). She also
                    deals fixed skill damage upon hitting enemies, regardless of
                    how many pellets land.
                  </li>
                  <li>
                    <strong>SAnis</strong> (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): Simply the enabler of Electric teams. Without her (and
                    also Crown), Electric teams just don't feel like worth
                    using. Reason? They want maximum reload speed. She also has
                    soft AoE damage that can help you manage distant mobs that
                    other units cannot reach (because S2 has no range).
                  </li>
                  <li>
                    <strong>Ein</strong> (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): True damage is goated with stat penalty. Also, constant
                    ATK buff stabilizes her damage, and her wipe can easily
                    finish off enemies.
                  </li>
                  <li>
                    <strong>Scarlet</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): She doesn't really become weak, but more like we are
                    moving her so that she harmonizes with the tier list rework.
                    Nowadays, Scarlet alone does not feel as rewarding as she is
                    before (likely due to CP inflation). Fortunately, Crown +
                    SAnis fixes her janky basic attacks. She is also rivaled by
                    Ein and exhibits similar performance. Both have wipes and
                    are still useful.
                  </li>
                  <li>
                    <strong>Tia</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): Naga has decided to leave her friend behind and "mated"
                    with Crown. Tia, who is now lonely, has somewhat become
                    obsolete. She is still the best B1 available if you do not
                    have Crown.
                  </li>
                  <li>
                    <strong>Dorothy</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Requires Privaty to work well, which leads to slot
                    pressure in Campaign. Alone, she does not do much. In fact,
                    with higher stat penalty, her ATK may even be too low to
                    even make an impression, let alone if you don't have ATK OL.
                  </li>
                  <li>
                    <strong>Bunny Alice</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): A reliable burst healer that does not sacrifice another
                    Burst Skill. We believe she fulfills that purpose well, but
                    in Campaign, Rapunzel just integrates better than her. The
                    reason is that Rapunzel allows you to burst one more time in
                    RH B1 comps (and you can even get another RH B1 burst in the
                    following rotation, bringing the total to 4).
                  </li>
                  <li>
                    <strong>Volume, Dolla, SHelm</strong> (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    A): Generally useless units that are used for CDR. They
                    don't do much and are just fillers/alternatives once you get
                    better ones. Nowadays, we have more options to choose from,
                    and oftentimes you can win without CDR.
                  </li>
                </ul>
                <li>
                  <strong>Bossing (Solo):</strong>
                </li>
                <ul>
                  <li>
                    <strong>Asuka</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): The demotion is not because she performs below
                    expectation, but rather, we are fixing the tier list so that
                    it is easier to differentiate meta units across tiers and
                    within the same rank. In the past, our gap of tolerance
                    before a unit is promoted or demoted was too large. About
                    10-20% of performance difference was not enough reason for
                    us to place them in a different tier. However, now, with
                    this update that tightens the scope from S~SSS and demotes
                    many units from S to A, we can now visualize/highlight these
                    performance gaps much more conveniently.
                  </li>
                  <ul>
                    <li>
                      Asuka has a powerful DPS potential, and her supportive
                      buffs are outstanding (better than anything). The reason
                      for her demotion is the fact that it is hard to scale her
                      further, and her support skills simply deactivate when
                      core is not present. Both Alice and Asuka lose a
                      tremendous amount of damage if an enemy does not have
                      core. But, Alice is different in the way that she still
                      can buff SBS (who excel against bosses with no core and
                      have relatively higher DPS among the meta units).
                    </li>
                    <li>
                      Meanwhile, Asuka loses all her support capabilities under
                      these circumstances. She's still a great DPS, though, and
                      comparatively stronger than a lot of other meta Nikkes
                      whose damage is inflated due to core presence (i.e.
                      Modernia). She also deals bonus damage against Wind.
                    </li>
                    <li>
                      In other words, Asuka would have deserved SSS if her
                      support skill is still usable against no-core enemies, but
                      that is not the case, making her supportive side less
                      versatile than Alice's.
                    </li>
                    <li>
                      Asuka still remains SSS tier in Bossing (Adds) due to her
                      Pierce.
                    </li>
                  </ul>
                  <li>
                    <strong>Modernia</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): The reason for her "demotion" is that her DPS doesn't
                    live up to the meta of the meta anymore (Alice, SBS). She
                    does not really offer anything except DMG. Her burst gen is
                    very high, however, and she can help teams deal with
                    projectiles and mobs, but there is no reason for her to be
                    SSS anymore. Her 15s Full Burst also makes her harder to
                    integrate in non 1-1-3 teams. She also cannot benefit from
                    Crown's ATK buff (since does not want to Burst).
                  </li>
                  <li>
                    <strong>xLudmilla</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): Still one of the most versatile semi-DPS and support
                    B3, but while Asuka's supportive capability plummets when
                    there is no core, Winter Ludmilla's DPS is the one affected
                    instead when there is no core. Her supportive capability
                    remains intact.
                  </li>
                  <li>
                    <strong>Ein</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Ein offers excellent DPS, and her True Damage proves
                    useful against bosses with high DEF. Ein is also more
                    scalable than Scarlet as she can benefit from True Damage
                    buffs. The reason why she is in S is because Ein does not
                    feel that worth using alone. Her DPS is high but not
                    towering, and she somewhat relies on SAnis to perform or at
                    least compete with the DPS from more superior teams.
                  </li>
                  <li>
                    <strong>Laplace (Treasure)</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Laplace Treasure has astronomical multipliers. Her
                    problem is the lack of ATK buff, and building an optimal
                    team around her at the moment is challenging. For example,
                    FrimaTr can be used to buff LaplaceTr and Ein, but then who
                    is the B2? If you use Rem, then you get slot pressure from
                    using Dolla/SHelm. And if you use Rem, Ein will not get ATK
                    buff. Similar problems arise when you try to perfect this
                    team because there will always be one DPS who gets
                    sacrificed. However, we will see if the introduction of Mari
                    will change her rating (again).
                  </li>
                  <li>
                    <strong>Rem</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Powercrept by Crown. Still a decent healer and semi-DPS.
                    Can buff Rocket Launchers. It is just hard to slot her into
                    various teams nowadays since there is always a better
                    option. Rem may still be used against Fire bosses.
                  </li>
                  <li>
                    <strong>S.Sakura</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Summer Sakura has big multipliers, but her issue is that
                    the team needs to destroy parts regularly to maximize her
                    damage. Unfortunately, not many bosses present this
                    behavior. SSakura also doesn't have ATK buffs in her kit and
                    has to rely on supports to shine. Thankfully, due to the
                    rising trend of Elemental, she may still see use against
                    Iron bosses, despite these setbacks.
                  </li>
                  <li>
                    <strong>Bunny Alice</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): We kind of expected too much from B. Alice being the
                    "core unit of SG team because she fixes Tove's issues", but
                    at the end most people prefer to reroll Tove's RNG than to
                    use B. Alice and lose damage due to slot pressure. In fact,
                    people now use SG teams with Blanc and Noir, so no more lack
                    of healers! B. Alice still offers nice ammo support, though,
                    and her healing does not sacrifice the use of other B1's
                    Burst Skill, so she still rocks
                  </li>
                  <li>
                    <strong>Maid Privaty</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Similar reasoning as the one we gave for Ein. Maid:
                    Privaty is a good DPS indeed, but the problem is that she
                    needs babysitting. Yes, her entire kit just doesn't work
                    without Tove or Leona. And when she does, she still relies
                    on BSoda to enable her. Moreover, she is only preferred
                    against Water element since usually there are better options
                    against other elements. For instance, Sugar against Electric
                    bosses.
                  </li>
                  <li>
                    <strong>2B, Diesel (Treasure)</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Simply power crept. With the incoming pierce support of
                    Mari, we think that Diesel will now have an extremely hard
                    time finding a team to fit into. Alongside that, with the
                    influx of not 1, but 2 Meta fire B3s in Asuka and Rei, 2B
                    will now have a hard time fitting into top 5 teams even in
                    fire raids, but still might pop in for rare situations due
                    to her ability to tank insane amounts of damage which often
                    are directed to the highest ATK unit.
                  </li>
                  <li>
                    <strong>S.Rosanna</strong> (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): A nikke who has shown how drastic dilution can be.
                    Summer Rosanna shines bright as she provides a very unique
                    Damage Taken debuff, and allows 3 DPS to benefit from it!
                    Each and every current meta DPS is filled to the brim with
                    ATK and Attack Damage buffs, as well as some even buffing
                    damage dealt to core. All of these self buffs dilute the
                    help they can receive from most meta B2s, such as Tia+Naga
                    and even Crown. This can especially be seen in the recent
                    releases of Asuka and Rei. Rosanna provides an almost always
                    undiluted Damage Taken Debuff that helps all units alike.
                    This helps to reduce pressure from Crown and Tiga as they
                    can buff some other units better comparatively than they
                    could buff Asuka for example. This aid in lowering slot
                    pressure is highly appreciated.
                  </li>
                </ul>
                <li>
                  <strong>PVP:</strong>
                </li>
                <ul>
                  <li>
                    RedHood B1 (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): The best offensive B1 support unit. Without her, some
                    wipers don't work properly or don't have enough damage to
                    truly wipe the enemy.
                  </li>
                  <li>
                    B. Soda (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): Comes with an aggressive and independent wipe. Counters
                    Noise's, Biscuit's, and SAnis's element and can evaporate
                    them easily once invested. Also can be used to deter or
                    punish unprotected Noise tanking.
                  </li>
                  <li>
                    Quiry (A{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    S): Stall teams need her to sustain considerably longer;
                    also doesn't feed Scarlet & Jackal. There is no stall
                    without Quiry.
                  </li>
                  <li>
                    Ein (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): A damage over time unit with her true damage dealing
                    feathers earns her a spot above others. Her DPS from her
                    burst skill is quite strong and Ein has a serious shout for
                    being used in the champions arena as a main B3.
                  </li>
                  <li>
                    2B (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Glaring counters. Can be countered in multiple ways.
                    Still exists as a good alternative overall.
                  </li>
                  <li>
                    S. Sakura (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Needs ATK buff to actually deal big damage. Her kit is
                    self-limiting. Low burst gen and feeding is the main reason
                    she's being demoted. Still highly regarded as a B3, and even
                    as an off burst unit.
                  </li>
                  <li>
                    Kilo (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Actually, her wipe is decently strong, but she is MG,
                    and she feeds. Nom nom.
                  </li>
                </ul>
              </ul>
              <h6>22.08.2024</h6>
              <ul>
                <li>
                  <strong>[Asuka Shikinami Langley]</strong> has been added to
                  the tier list.
                </li>
              </ul>
              <h6>19.08.2024</h6>
              <ul>
                <li>
                  <strong>[Frima - Treasure]</strong> and{' '}
                  <strong>[Viper - Treasure]</strong> have been added to the
                  tier list.
                </li>
              </ul>
              <h6>10.08.2024</h6>
              <ul>
                <li>
                  <strong>[Zwei]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>03.08.2024</h6>
              <ul>
                <li>
                  <strong>[Exia - Treasure]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>26.07.2024</h6>
              <ul>
                <li>
                  <strong>[Ein]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>25.07.2024</h6>
              <ul>
                <li>
                  <strong>[Noah]</strong> SS &gt; SSS in PvP. Her burst gen has
                  been patched, she now hits with both bullets, which means her
                  burst gen is now ×2. This puts her burst gen above all Clip
                  SGs, and immensely increases her already near constant
                  presence in PvP.
                </li>
              </ul>
              <h6>15.07.2024</h6>
              <ul>
                <li>
                  <strong>[Diesel - Treasure]</strong> has been added to the
                  tier list.
                </li>
              </ul>
              <h6>12.07.2024</h6>
              <ul>
                <li>
                  <strong>[Rosanna: Chic Ocean]</strong> has been added to the
                  tier list.
                </li>
              </ul>
              <h6>06.07.2024</h6>
              <ul>
                <li>
                  <strong>[Sakura Summer]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>22.06.2024</h6>
              <ul>
                <li>
                  <strong>[Laplace - Treasure]</strong> has been added to the
                  tier list.
                </li>
              </ul>
              <h6>20.06.2024</h6>
              <ul>
                <li>
                  <strong>[Clay]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>08.06.2024</h6>
              <ul>
                <li>
                  <strong>[Alice: Wonderland Bunny]</strong> has been added to
                  the tier list.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
        {/* <div className="promo-top with-margin">
          <OutboundLink
            href="https://topup.bot/?game=nikke&utm_source=prydwenf&utm_medium=referral&utm_campaign=feb"
            target="_blank"
          >
            <StaticImage
              src="../../images/nikke/promo_tier.webp"
              alt="Toptup.bot Promotion"
              className="desktop"
            />
            <StaticImage
              src="../../images/nikke/promo_mobile.webp"
              alt="Toptup.bot Promotion"
              className="mobile"
            />
            <p className="mobile text">
              <strong>Topup.bot</strong> - Gems at a 20% discount, delivered
              instantly to UID!
            </p>
          </OutboundLink>
        </div> */}
        <div className="tier-list-header">
          <p className="title">
            Tier List{' '}
            <span>
              ({sortOption === 'Story_LD' && 'Story - low deficit'}
              {sortOption === 'Story_HD' && 'Story - high deficit'}
              {sortOption === 'Boss' && 'Bossing'}
              {sortOption === 'PVP' && 'PVP'})
            </span>
          </p>
          <p className="sub-title">
            You're currently viewing the{' '}
            <strong>
              {sortOption === 'Story_LD' && 'Story (low deficit)'}
              {sortOption === 'Story_HD' && 'Story (high deficit)'}
              {sortOption === 'Boss' && 'Bossing'}
              {sortOption === 'PVP' && 'PVP'}
            </strong>{' '}
            tier list.{' '}
            {sortOption === 'Story_LD' &&
              'This tier list is aimed toward casual players for Campaign, who prefer to advance with reduced difficulty, as well as for the mode Simulation Overclock.'}
            {sortOption === 'Story_HD' &&
              'This tier list refers to any late-game story-clearing content where people attempt to clear stages, whether in Campaign or Tower, with over >25% CP deficit.'}
            {sortOption === 'PVP' &&
              'It shows how a character performs in the Arena - both the normal arena and the three team variant (Special Arena).'}
            {sortOption === 'Boss' &&
              'It shows how a character performs in various fights against bosses - both those who are solo and those who spawn adds.'}{' '}
            Use the switcher below to view a different tier list.
          </p>
        </div>
        <div className="tier-list-switcher">
          <div
            className={`option early ${
              sortOption === 'Story_LD' && 'selected'
            }`}
            onClick={() => setSortOption('Story_LD')}
          >
            <p>Story (low deficit)</p>
          </div>
          <div
            className={`option late ${sortOption === 'Story_HD' && 'selected'}`}
            onClick={() => setSortOption('Story_HD')}
          >
            <p>Story (high deficit)</p>
          </div>
          <div
            className={`option boss ${sortOption === 'Boss' && 'selected'}`}
            onClick={() => setSortOption('Boss')}
          >
            <p>Bossing</p>
          </div>
          <div
            className={`option pvp ${sortOption === 'PVP' && 'selected'}`}
            onClick={() => setSortOption('PVP')}
          >
            <p>PVP</p>
          </div>
        </div>
        <div className="employees-filter-bar nikke">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-nikke">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-header burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-header burst-3">
                Burst <span>III</span>
              </div>
            </div>
          </div>
          <div className="custom-tier tier-sss first">
            <div className="tier-rating sss">
              <span>SSS</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SSS')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SSS')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SSS')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-ss">
            <div className="tier-rating ss">
              <span>SS</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SS')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SS')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SS')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className={`meta-line ${
              sortOption != 'Boss' && sortOption != 'PVP' && 'visible'
            }`}
          >
            <h5 className="meta-header meta">
              <FontAwesomeIcon icon={faAnglesUp} width="18" /> Meta{' '}
              <FontAwesomeIcon icon={faAnglesUp} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-s">
            <div className="tier-rating s">
              <span>S</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'S')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'S')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'S')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className={`meta-line ${
              (sortOption === 'Boss' || sortOption === 'PVP') && 'visible'
            }`}
          >
            <h5 className="meta-header meta">
              <FontAwesomeIcon icon={faAnglesUp} width="18" /> Meta{' '}
              <FontAwesomeIcon icon={faAnglesUp} width="18" />
            </h5>
          </div>
          <div
            className={`meta-line ${
              (sortOption === 'Story_LD' || sortOption === 'Story_HD') &&
              'visible'
            }`}
          >
            <h5 className="meta-header alt">
              <FontAwesomeIcon icon={faAnglesUp} width="18" /> Best replacement
              / situational <FontAwesomeIcon icon={faAnglesUp} width="18" />
            </h5>
          </div>
          <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
          <div className="custom-tier tier-a">
            <div className="tier-rating a">
              <span>A</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'A')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'A')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'A')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={`meta-line ${sortOption === 'Boss' && 'visible'}`}>
            <h5 className="meta-header alt">
              <FontAwesomeIcon icon={faAnglesUp} width="18" /> Best replacement
              / situational <FontAwesomeIcon icon={faAnglesUp} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-b">
            <div className="tier-rating b">
              <span>B</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'B')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'B')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'B')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={`meta-line ${sortOption === 'PVP' && 'visible'}`}>
            <h5 className="meta-header alt">
              <FontAwesomeIcon icon={faAnglesUp} width="18" /> Best replacement
              / situational <FontAwesomeIcon icon={faAnglesUp} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-c">
            <div className="tier-rating c">
              <span>C</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'C')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'C')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'C')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
          <div className="custom-tier tier-d">
            <div className="tier-rating d">
              <span>D</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'D')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'D')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'D')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-e">
            <div className="tier-rating e">
              <span>E</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'E')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'E')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'E')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-f">
            <div className="tier-rating f">
              <span>F</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'F')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'F')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'F')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | NIKKE | Prydwen Institute"
    description="Tier list for Goddess of the Victory: NIKKE that rates all characters."
    game="nikke"
  />
);
